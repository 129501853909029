.fc-event-title {
  color: white;
}
.fc-event-time {
  color: white;
}
.fc-daygrid-event-harness:hover {
  background-color: rgb(228, 227, 227) !important;
}

.fc-toolbar {
  flex-wrap: wrap;
  justify-content: space-between;
}

.fc-toolbar-title {
  font-size: 1rem;
  text-align: center;
}

.fc .fc-button {
  padding: 0.3rem 0.5rem;
  font-size: 0.875rem;
}
