@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
  margin: 0;
  font-family: "system-ui";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button,
input {
  cursor: pointer;
}

.App {
  width: 100%;
  min-height: 100vh;
  background-color: #1c2c4c;
  color: #4c768d;
  padding-top: 60px;
}

/* navbar */
.nav-bar {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4c768d;
  color: #242443;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.sign-in {
  border: none;
  background-color: transparent;
}
.sign-in > img {
  height: 30px;
  width: auto;
}
.sign-out {
  padding: 5px 10px;
  border-radius: 5px;
  color: #88dded;
  border: 1px solid #1c2c4c;
  background-color: #1c2c4c;
  font-weight: 600;
}

/* welcome page */
.welcome {
  padding: 30px;
  text-align: center;
  margin-top: 40px;
  color: #7cc5d9;
}
.welcome :is(h2, p, img) {
  margin-bottom: 20px;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: transparent;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #6c6b6b;
}
